import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>The Price of Connectivity: How Canada Compares Globally 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
       
        

            
            <StaticImage
            layout="constrained"
            src="images/The-Price-of-Connectivity-How-Canada-Compares-Globally.png"
            alt="Purple Cow Internet - Picking the right plan"
            />
            <br></br>
            <br></br>
            <h1>The Price of Connectivity: How Canada Compares Globally</h1>
            <p>In an era where internet access is nearly as essential as electricity, Canadians face a stark reality: they pay some of the highest rates for internet and cellular services in the world. This issue stems largely from a market dominated by a few large corporations that limit competition and drive up pricing.</p> 
            <p>The Canadian market is dominated by a few large corporations, which own not only the primary brands but also various subsidiaries. This lack of competition has led to high prices, mediocre service, and stagnant innovation. According to a report featuring global comparisons, Canada's telecommunications fees are among the highest, with Canadian companies like Rogers, Bell, and Telus ranking as the priciest providers globally. The situation is exacerbated by the CRTC's recent decision to reverse a reduction in wholesale rates, which had initially promised to lower costs for consumers by allowing smaller ISPs like Purple Cow Internet to compete more effectively.</p>
            <p>The recent merger of Rogers and Shaw exemplifies the continuing trend of oligopolism  within Canada's telecommunications sector. Despite the Competition Bureau's opposition, citing concerns that the $26-billion takeover would reduce competition and lead to higher prices, the Federal Court of Appeal upheld the merger. This decision dismissed warnings that eliminating Shaw as a competitive force—particularly noted for its aggressive pricing in Western Canada—would disadvantage consumers. This scenario underscores the ongoing challenge: large corporations are becoming even more dominant, often at the expense of competitive diversity and consumer choice. The need for vigilant oversight and robust competition policies has never been more critical, as these conglomerates expand their reach, further entrenching their market positions.</p>
            <p>Recent studies, including one by Picodi, have placed Canada among the top five most expensive countries for internet access. In their analysis, which surveyed 233 ISPs across 62 countries, Canada's average cost for a 100Mbps internet connection stands at $62.36 USD (approximately $81.70 CAD).</p> 
            <StaticImage
            layout="constrained"
            src="images/Stats-on-Internet-costs.png"
            alt="Purple Cow Internet - Picking the right plan"
            />
            <p>Despite the high costs, Canada does boast some of the best networks available, suggesting that Canadians are paying a premium for quality. However, the monopolistic nature of the telecommunications industry here means fewer choices and higher prices for consumers, impacting the overall economy and contributing to wider economic issues such as lower productivity and wealth inequality.</p>
            <p>In this context, new players like Purple Cow Internet are critical. We represent not just an alternative, but a challenge to the entrenched norms of the telecommunications industry. "At Purple Cow, we started with a clear goal—to drive down the cost of internet access," says Bradley Farquhar, CEO of Purple Cow Internet. "We're not just providing a service; we're advocating for fair pricing and transparency in an industry that's kept consumers in the dark for too long."</p>
            <p>As the Canadian telecommunications market pushes forward, it's vital for consumers to support competition in the marketplace. Consumers are not merely selecting an internet provider; they are casting a vote against the oligopolistic practices that have long plagued the Canadian market. Each new subscriber not only gains access to reliable and fairly priced internet but also supports a broader movement towards a more competitive market landscape.</p>
            <p>While the journey is long, the goal is clear: to create a market where competition thrives, prices are fair, and consumers are not merely subjects to corporate giants but empowered participants in a vibrant, competitive sector. By choosing alternatives and supporting new entrants, Canadians can help reshape the telecommunications landscape—one connection at a time.</p>


            
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>

      </section>
    </LayoutNew>
  )
}
